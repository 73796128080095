import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  Bell, Home, Users, FileText, ChevronRight, BookOpen, EyeOff, Check, X, User, LogOut, AlertCircle, Phone, Mail, MapPin, ExternalLink 
} from 'lucide-react';
import { useAuth } from './contexts/AuthContext';
import { useNavigate, Outlet, NavLink } from 'react-router-dom';
import logo from './new_logo.png';
import { loadAvatarImage } from './utils/avatarLoader';
import notificationService from './services/notificationSV';
import { 
  setUnreadNotifications, 
  addNotification, 
  removeNotification, 
  clearAllNotifications, 
  addTempBookingRequest,
  setTempBookingRequests,
  removeTempBookingByMatch,
  cleanExpiredRequests,
  updateNotificationReadStatus,
  initializeFromStorage 
} from './store/notificationsSlice';
import moment from 'moment-timezone';
import ChatWidget from './components/ChatWidget';
import config from './config';


const REJECT_REASONS = [
  'Family commitment',
  'Personal emergency',
  'Prior appointment',
  'Health condition',
  'Technical issues',
  'Schedule conflict',
  'Professional development',
];



const Layout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const { user, axiosWithCsrf } = useAuth();
  
  const [showNotifications, setShowNotifications] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const timeoutRef = useRef(null);
  const notificationRef = useRef(null);
  
  const tempBookingRequests = useSelector(state => state.notifications.tempBookingRequests);
  const unreadNotifications = useSelector(state => state.notifications.unreadItems);
  
  
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [currentRejectRequest, setCurrentRejectRequest] = useState(null);
  const [selectedReason, setSelectedReason] = useState('');
  const [alternativeDate, setAlternativeDate] = useState('');
  const [alternativeTime, setAlternativeTime] = useState('');
  const [successModal, setSuccessModal] = useState({ show: false, message: '', icon: '' });
  const [errorModal, setErrorModal] = useState({ show: false, message: '' });
  const [showIgnoreModal, setShowIgnoreModal] = useState(false);
  const [currentIgnoreRequest, setCurrentIgnoreRequest] = useState(null);
  


  // 初始化存储的数据
  useEffect(() => {
    dispatch(initializeFromStorage());
  }, [dispatch]);

  useEffect(() => {
    // 初始清理
    dispatch(cleanExpiredRequests());
  
    // 设置定期清理（例如每分钟检查一次）
    const cleanupInterval = setInterval(() => {
      dispatch(cleanExpiredRequests());
    }, 60000); // 60000ms = 1分钟
  
    // 清理定时器
    return () => {
      clearInterval(cleanupInterval);
    };
  }, [dispatch]);
  
  // 监听通知事件
  useEffect(() => {
    if (user) {
      const handleNewNotification = (notification) => {
        console.log('处理新通知:', notification);

              // 如果是确认通知，不要中断当前的操作流程
        if (notification.type === 'sent_confirmation') {
          console.log('收到确认通知，继续执行原有流程');
          dispatch(addNotification(notification));
          return; // 直接返回，不做其他处理
        }
        
        if (notification.type === 'TEMP_BOOKING_REQUEST') {
          const courseDateTime = moment.tz(
            `${notification.context.courseDate} ${notification.context.startTime}`,
            'YYYY-MM-DD HH:mm:ss',
            'Asia/Shanghai'
          );
          
          if (courseDateTime.isAfter(moment().tz('Asia/Shanghai'))) {
            console.log('添加有效的临时预订请求:', notification);
            dispatch(addTempBookingRequest(notification));
          } else {
            console.log('临时预订请求已过期:', notification);
          }
        } else if (notification.type === 'TEMP_BOOKING_CANCELLED') {
          console.log('处理取消预订请求:', notification);
          dispatch(removeTempBookingByMatch({
            studentId: notification.context.studentId,
            courseDate: notification.context.courseDate,
            startTime: notification.context.startTime
          }));
          dispatch(addNotification(notification));
        } else {
          dispatch(addNotification(notification));
        }
      };
  
      const handleNotificationsUpdate = (notifications) => {
        console.log('处理通知更新:', notifications);
        
        // 分离并处理临时预订请求
        const tempBookings = notifications.filter(n => 
          n.type === 'TEMP_BOOKING_REQUEST' && 
          moment.tz(
            `${n.context.courseDate} ${n.context.startTime}`,
            'YYYY-MM-DD HH:mm:ss',
            'Asia/Shanghai'
          ).isAfter(moment().tz('Asia/Shanghai'))
        );
        
        console.log('有效的临时预订请求:', tempBookings);
        
        // 更新临时预订请求
        dispatch(setTempBookingRequests(tempBookings));
        
        // 处理其他通知
        const otherNotifications = notifications.filter(n => n.type !== 'TEMP_BOOKING_REQUEST');
        dispatch(setUnreadNotifications(otherNotifications));
      };
  
      const initializeNotifications = async () => {
        try {
          await notificationService.connect(user.id, 'teacher');
          
          // 从 localStorage 恢复状态
          dispatch(initializeFromStorage());
          
          // 设置事件监听器
          notificationService.addListener('new_notification', handleNewNotification);
          notificationService.addListener('notifications_update', handleNotificationsUpdate);
          
        } catch (error) {
          console.error('初始化通知服务失败:', error);
        }
      };
  
      initializeNotifications();
  
      return () => {
        notificationService.removeListener('new_notification', handleNewNotification);
        notificationService.removeListener('notifications_update', handleNotificationsUpdate);
      };
    }
  }, [user, dispatch]);
  
  // 监视临时预订请求的变化
  
  
  useEffect(() => {
    console.log('临时预订请求更新:', tempBookingRequests);
  }, [tempBookingRequests]);

  // useEffect(() => {
  //   const cleanupInterval = setInterval(() => {
  //     dispatch(cleanExpiredRequests());
  //   }, 60000); // 每分钟检查一次
  
  //   return () => clearInterval(cleanupInterval);
  // }, [dispatch]);

  // 处理临时预订请求
  const handleTempBookingRequest = async (requestId, action, responseMessage = '') => {
    try {
      const request = tempBookingRequests.find(req => req.id === requestId);
      if (!request) return;
  
      // 1. 发送处理预订的请求
      const response = await axiosWithCsrf.post(`${config.API_BASE_URL}/handle_temp_booking.php`, {
        requestId: request.id,
        action: action,
        teacherId: user.id,
        studentId: request.context.studentId,
        studentName: request.context.studentName,
        courseDate: request.context.courseDate,
        startTime: request.context.startTime,
        endTime: request.context.endTime,
        courseName: request.context.courseName,
        responseMessage: responseMessage,
        numberOfLessons: calculateLessons(request.context.startTime, request.context.endTime)
      });

      if (response.data.success) {

                // 5. 显示成功提示
        setSuccessModal({
          show: true,
          message: action === 'approve' 
            ? `Successfully approved ${calculateLessons(request.context.startTime, request.context.endTime)} lessons booking request from ${request.context.studentName}`
            : `Successfully rejected booking request from ${request.context.studentName}`,
          icon: action === 'approve' ? 'check' : 'x'
        });

        setTimeout(() => {
          setSuccessModal({ show: false, message: '', icon: '' });
        }, 2000);
        // 2. 发送 WebSocket 通知
        try {
          // 发送给学生
          await notificationService.sendNotification(
            action === 'approve' ? 'TEMP_BOOKING_APPROVED' : 'TEMP_BOOKING_REJECTED',
            request.context.studentId,
            {
              teacherId: user.id,
              teacherName: user.name,
              studentId: request.context.studentId,
              studentName: request.context.studentName,
              courseDate: request.context.courseDate,
              startTime: request.context.startTime,
              endTime: request.context.endTime,
              courseName: request.context.courseName,
              responseMessage: responseMessage
            }
          );
        } catch (error) {
          console.error('发送通知失败:', error);
        }

        // 3. 从本地移除临时预订请求
        console.log('移除本地临时预订请求...');
        dispatch(removeTempBookingByMatch({
          studentId: request.context.studentId,
          courseDate: request.context.courseDate,
          startTime: request.context.startTime
        }));

        // 4. 删除服务器端通知
        console.log('删除服务器端通知...');
        try {
          await notificationService.deleteNotification(requestId);
          console.log('成功删除服务器端通知:', requestId);
        } catch (error) {
          console.error('删除服务器端通知失败:', error);
          // 考虑是否需要继续执行
        }



      } else {
        setErrorModal({
          show: true,
          message: response.data.message || 'Failed to process booking request'
        });
      }
    } catch (error) {
      console.error('Failed to process booking request:', error);
      setErrorModal({
        show: true,
        message: 'Failed to process booking request'
      });
    }
  };

  // 打开拒绝原因与替代时间的弹窗
  const openRejectModal = (request) => {
    setCurrentRejectRequest(request);
    setShowRejectModal(true);
  };

  // 关闭弹窗并重置状态
  const closeRejectModal = () => {
    setShowRejectModal(false);
    setCurrentRejectRequest(null);
    setSelectedReason('');
    setAlternativeDate('');
    setAlternativeTime('');
  };



  // 提交拒绝请求
  const submitReject = () => {
    if (currentRejectRequest && selectedReason) {
      // 合并原因和替代时间为一个 responseMessage
      const formattedTime = `${alternativeDate} ${alternativeTime}`;
      const responseMessage = `原因: ${selectedReason}\n空闲: ${formattedTime}`;
      
      handleTempBookingRequest(
        currentRejectRequest.id,
        'reject',
        responseMessage
      );
      closeRejectModal();
    }
  };

  const calculateLessons = (startTime, endTime) => {
    const start = moment(`2000-01-01 ${startTime}`);
    const end = moment(`2000-01-01 ${endTime}`);
    const duration = moment.duration(end.diff(start));
    const minutes = duration.asMinutes();
    return Math.ceil(minutes / 30);
  };

  const validTempBookingRequests = tempBookingRequests.filter(request => {
    // 将课程日期和时间组合成完整的日期时间字符串，并使用上海时区
    const courseDateTime = moment.tz(
      `${request.context.courseDate} ${request.context.startTime}`, 
      'YYYY-MM-DD HH:mm:ss',
      'Asia/Shanghai'
    );
    
    // 获取当前上海时间
    const now = moment().tz('Asia/Shanghai');
    
    // 如果课程时间大于当前时间，则该请求仍然有效
    return courseDateTime.isAfter(now);
  });


  const generateTimeSlots = () => {
    const slots = [];
    const start = 9; // 9:00 AM
    const end = 22; // 9:00 PM
  
    for (let hour = start; hour <= end; hour++) {
      const formattedHour = hour.toString().padStart(2, '0');
      slots.push(`${formattedHour}:00:00`);
      slots.push(`${formattedHour}:30:00`);
    }
  
    return slots;
  };


  const handleIgnoreRequest = (requestId) => {
    const request = tempBookingRequests.find(req => req.id === requestId);
    if (!request) return;
    
    // 设置当前要忽略的请求并显示确认模态框
    setCurrentIgnoreRequest(request);
    setShowIgnoreModal(true);
  };

  const confirmIgnoreRequest = async () => {
    // 首先复制当前请求信息，因为后面我们会清空状态
    const studentName = currentIgnoreRequest?.context?.studentName || '';
    const requestId = currentIgnoreRequest?.id;
    
    try {
      if (!currentIgnoreRequest) return;
      
      // 从本地移除临时预订请求
      console.log('Removing temporary booking request...');
      dispatch(removeTempBookingByMatch({
        studentId: currentIgnoreRequest.context.studentId,
        courseDate: currentIgnoreRequest.context.courseDate,
        startTime: currentIgnoreRequest.context.startTime
      }));
  
      // 立即关闭模态框，无论后续操作成功与否
      console.log('Closing modal...');
      setShowIgnoreModal(false);
      setCurrentIgnoreRequest(null);
  
      // 删除服务器端通知
      console.log('Deleting server notification...');
      if (requestId) {
        try {
          await notificationService.deleteNotification(requestId);
          
          // 显示成功提示
          setSuccessModal({
            show: true,
            message: `Successfully ignored booking request from ${studentName}`,
            icon: 'x'
          });
  
          setTimeout(() => {
            setSuccessModal({ show: false, message: '', icon: '' });
          }, 2000);
        } catch (error) {
          console.error('Failed to delete server notification:', error);
          setErrorModal({
            show: true,
            message: 'Failed to ignore booking request. Please try again.'
          });
        }
      }
    } catch (error) {
      console.error('Error ignoring booking request:', error);
      
      // 确保模态框关闭
      setShowIgnoreModal(false);
      setCurrentIgnoreRequest(null);
      
      setErrorModal({
        show: true,
        message: 'An error occurred while processing your request'
      });
    }
  };
  // 切换通知面板
  const toggleNotifications = () => {
    setShowNotifications(prev => !prev);
    
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    
    if (!showNotifications && unreadNotifications.length === 0) {
      timeoutRef.current = setTimeout(() => {
        setShowNotifications(false);
      }, 2000);
    }
  };

  // 处理通知点击事件
  const handleNotificationClick = async (notification) => {
    try {
      await notificationService.markAsRead(notification.id);
      
      // 如果是临时预订请求且未处理，不从临时预订列表中移除
      if (notification.type !== 'TEMP_BOOKING_REQUEST' || notification.handled) {
        dispatch(removeNotification(notification.id));
      } else {
        // 只更新通知的已读状态，不移除未处理的临时预订请求
        dispatch(updateNotificationReadStatus(notification.id));
      }
      
      // 根据通知类型导航到相应页面
      switch(notification.type) {
        case 'class_reminder':
          navigate(`/class/${notification.course_id}`);
          break;
        case 'homework_submission':
          navigate(`/homework/${notification.homework_id}`);
          break;
        case 'sent_confirmation':
          // 对于确认通知，可能不需要导航
          break;
        default:
          // 对于临时预订请求，不进行导航
          if (notification.type !== 'TEMP_BOOKING_REQUEST') {
            navigate('/notifications');
          }
      }

      // 如果没有未读通知了，关闭通知面板
      if (unreadNotifications.length === 1) {
        setShowNotifications(false);
      }
      
    } catch (error) {
      console.error('标记通知已读失败:', error);
    }
  };

  // 处理点击外部区域关闭通知面板
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setShowNotifications(false);
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  // 鼠标移动显示/隐藏导航栏
  // useEffect(() => {
  //   const handleMouseMove = (e) => {
  //     if (e.clientY <= 150) {
  //       setShowNav(true);
  //     } else if (e.clientY > 150) {
  //       setShowNav(false);
  //     }
  //   };
  //   window.addEventListener('mousemove', handleMouseMove);
  //   return () => {
  //     window.removeEventListener('mousemove', handleMouseMove);
  //   };
  // }, []);

    useEffect(() => {
      // 设置导航栏永久显示
      setShowNav(true);
    }, []);



  // 处理登出
  const handleLogout = () => {
    if (navigate) {
      navigate('/login');
    } else {
      console.error('Navigation is not available');
    }
  };

  // 处理个人资料点击
  const handleProfileClick = () => {
    navigate('/teacher-profile');
  };

  // 处理Logo点击
  const handleLogoClick = () => {
    navigate('/home');
  };

  // 加载头像
  const [avatar, setAvatar] = useState(null);
  
  const loadAvatar = async () => {
    if (user && user.id) {
      try {
        const avatarUrl = await loadAvatarImage(user.id, user.name);
        setAvatar(avatarUrl);
      } catch (error) {
        console.error('Error loading avatar:', error);
      }
    }
  };

  useEffect(() => {
    if (user) {
      loadAvatar();
    }
  }, [user]);


  <style jsx>{`
    .group:hover .group-dropdown {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
    
    .group-dropdown {
      opacity: 0;
      visibility: hidden;
      transform: translateY(-10px);
      transition: opacity 0.2s ease, transform 0.2s ease, visibility 0.2s;
    }
  `}</style>

  return (
    <div className="flex flex-col min-h-screen bg-white">
      {/* Banner Notification: Handle TEMP_BOOKING_REQUEST */}
      {validTempBookingRequests.length > 0 && (
        <div className="fixed top-12 w-full left-0 right-0 z-50 animate-slideDown">
          <div className="bg-gradient-to-r from-blue-50 to-indigo-100 shadow-lg border-b border-blue-200 animate-gradientMove">
            <div className="container mx-auto px-8 py-4">
              <div className="flex items-center justify-between relative">
                {/* 左侧闪烁提示点 */}
                <div className="absolute -left-4 top-1/2 -translate-y-1/2">
                  <span className="flex h-3 w-3">
                    <span className="animate-blink absolute inline-flex h-full w-full rounded-full bg-orange-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-orange-500"></span>
                  </span>
                </div>

                <div className="flex items-center flex-grow">
                    <AlertCircle className="text-orange-500 mr-3 flex-shrink-0 animate-softBounce" size={24} />
                    <div className="min-w-0">
                      <h3 className="font-semibold text-orange-800 truncate hover:text-orange-900 transition-colors">
                        Booking Request from {validTempBookingRequests[0].context.studentName}
                      </h3>
                      <p className="text-sm text-orange-600 flex items-center flex-wrap">
                        <span>Course: {validTempBookingRequests[0].context.courseName}</span>
                        <span className="mx-2 text-orange-300">|</span>
                        <span>Date: {validTempBookingRequests[0].context.courseDate}</span>
                        <span className="mx-2 text-orange-300">|</span>
                        <span>Time: {validTempBookingRequests[0].context.startTime} - {validTempBookingRequests[0].context.endTime}</span>
                        {/* 添加课程节数显示 */}
                        <span className="mx-2 text-orange-300">|</span>
                        <span className="text-orange-700 font-medium">
                          {calculateLessons(
                            validTempBookingRequests[0].context.startTime,
                            validTempBookingRequests[0].context.endTime
                          )} Lessons
                        </span>
                      </p>
                      <div className="flex items-center mt-1">
                        <p className="text-xs text-gray-500">
                          Requested at: {moment(validTempBookingRequests[0].timestamp).format('YYYY-MM-DD HH:mm:ss')}
                        </p>
                        {validTempBookingRequests.length > 1 && (
                          <div className="flex items-center ml-3">
                            <span className="relative flex h-2 w-2 mr-1">
                              <span className="animate-blink absolute inline-flex h-full w-full rounded-full bg-orange-400 opacity-75"></span>
                              <span className="relative inline-flex rounded-full h-2 w-2 bg-orange-500"></span>
                            </span>
                            <p className="text-xs text-orange-500 font-semibold animate-breathe">
                              + {validTempBookingRequests.length - 1} more pending requests
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                <div className="flex items-center space-x-2 ml-4 flex-shrink-0">
                  <button
                    onClick={() => handleTempBookingRequest(validTempBookingRequests[0].id, 'approve')}
                    className="group px-4 py-2 bg-green-500 text-white rounded 
                      hover:bg-green-600 transition-all duration-300 
                      transform hover:scale-105 hover:shadow-lg flex items-center"
                  >
                    <Check size={16} className="mr-1 group-hover:animate-softBounce" />
                    Accept
                  </button>
                  <button
                    onClick={() => openRejectModal(validTempBookingRequests[0])}
                    className="group px-4 py-2 bg-red-500 text-white rounded 
                      hover:bg-red-600 transition-all duration-300 
                      transform hover:scale-105 hover:shadow-lg flex items-center"
                  >
                    <X size={16} className="mr-1 group-hover:animate-softBounce" />
                    Reject
                  </button>
                  <button
                    onClick={() => handleIgnoreRequest(validTempBookingRequests[0].id)}
                    className="group px-4 py-2 bg-gray-500 text-white rounded 
                      hover:bg-gray-600 transition-all duration-300 
                      transform hover:scale-105 hover:shadow-lg flex items-center relative"
                  >
                    <EyeOff size={16} className="mr-1 group-hover:animate-softBounce" />
                    Ignore
                    <span className="absolute top-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap">
                      Ignore this request
                    </span>
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>
      )}

        {/* Reject Reason and Alternative Time Modal */}
        {showRejectModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded shadow-lg w-[480px]">
              <h2 className="text-lg font-semibold mb-4">Reject Booking Request</h2>
              
              {/* Predefined Rejection Reason Selection */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Rejection Reason
                </label>
                <select
                  value={selectedReason}
                  onChange={(e) => setSelectedReason(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">Please select a reason</option>
                  {REJECT_REASONS.map((reason) => (
                    <option key={reason} value={reason}>
                      {reason}
                    </option>
                  ))}
                </select>
              </div>

              {/* Alternative Time Inputs */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Alternative Available Time
                </label>
                <div className="flex space-x-2">
                  <input
                    type="date"
                    value={alternativeDate}
                    onChange={(e) => setAlternativeDate(e.target.value)}
                    min={new Date().toISOString().split('T')[0]}
                    className="flex-1 p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
                  />
                  <select
                    value={alternativeTime}
                    onChange={(e) => setAlternativeTime(e.target.value)}
                    className="flex-1 p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">Please select a time</option>
                    {generateTimeSlots().map((slot) => (
                      <option key={slot} value={slot}>
                        {slot}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Button Group */}
              <div className="flex justify-end space-x-2">
                <button
                  onClick={closeRejectModal}
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                >
                  Cancel
                </button>
                <button
                  onClick={submitReject}
                  className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={!selectedReason || !alternativeDate || !alternativeTime}
                >
                  Confirm Rejection
                </button>
              </div>
            </div>
          </div>
        )}

        {/* 成功提示模态框 */}
        {successModal.show && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="bg-white rounded-lg shadow-lg p-6 transform transition-all duration-300 ease-in-out">
                <div className="flex flex-col items-center">
                  {successModal.icon === 'check' ? (
                    <Check className="w-16 h-16 text-green-500 mb-4" />
                  ) : (
                    <X className="w-16 h-16 text-orange-500 mb-4" />
                  )}
                  <p className="text-lg font-semibold text-gray-800">{successModal.message}</p>
                  {/* 添加关闭按钮 */}
                  <button 
                    className="mt-4 px-4 py-2 bg-gray-200 rounded-lg hover:bg-gray-300"
                    onClick={() => setSuccessModal({ show: false, message: '', icon: '' })}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

        {/* 错误提示模态框 */}
        {errorModal.show && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg p-6">
              <div className="flex flex-col items-center">
                <AlertCircle className="w-16 h-16 text-red-500 mb-4" />
                <p className="text-lg font-semibold text-gray-800">{errorModal.message}</p>
                <button 
                  className="mt-4 px-4 py-2 bg-gray-200 rounded-lg"
                  onClick={() => setErrorModal({ show: false, message: '' })}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

      {/* Fixed Header */}
      <header className="fixed top-0 left-0 right-0 bg-gradient-to-r from-sky-400 to-indigo-500 shadow-md z-20">
        <div className="container mx-auto px-8 py-2 flex justify-between items-center">
          <img 
            src={logo} 
            alt="Theory ABC Logo" 
            className="h-6 md:h-6 lg:h-6 w-auto drop-shadow-lg filter brightness-110 cursor-pointer" 
            onClick={handleLogoClick}
          />
          <div className="flex items-center space-x-6">
            <div className="relative" ref={notificationRef}>
              <Bell 
                size={25} 
                className="cursor-pointer text-white"
                onClick={toggleNotifications}
              />
              {unreadNotifications.length > 0 && (
                <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                  {unreadNotifications.length}
                </span>
              )}
              {showNotifications && (
                <div className="absolute -left-40 mt-2 w-96 bg-gray-100 rounded-sm shadow-lg overflow-hidden z-20">
                  <div className="bg-gray-100 text-orange-500 px-4 py-2 font-bold text-lg ">
                    Notifications
                  </div>
                  <div className="max-h-96 overflow-y-auto">
                    {unreadNotifications.length > 0 ? (
                      <>
                        {unreadNotifications.slice(0, 10).map((notification, index) => (
                          <div 
                            key={notification.id} 
                            className="px-4 py-3 text-sm text-gray-700 hover:bg-gray-200 cursor-pointer border-b border-gray-200"
                            onClick={() => handleNotificationClick(notification)}
                          >
                            <p className="mb-1">
                              <span className="font-medium">{index + 1}. </span>
                              {notification.content}
                            </p>
                            <p className="text-xs text-gray-500">
                              {moment(notification.timestamp).format('YYYY.MM.DD HH:mm:ss')}
                            </p>
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className="px-4 py-3 text-sm text-gray-700">No unread notifications</div>
                    )}
                  </div>
                  <div className="flex justify-between items-center bg-gray-200 px-4 py-2 text-sm">
                    <button 
                      onClick={() => navigate('/notifications')}
                      className="text-orange-500 hover:text-orange-800 font-semibold flex items-center"
                    >
                      More <ChevronRight size={16} className="ml-1" />
                    </button>
                    <button 
                      onClick={async () => {
                        try {
                          await notificationService.markAllAsRead();
                          dispatch(clearAllNotifications());
                          setShowNotifications(false);
                        } catch (error) {
                          console.error('Failed to mark all notifications as read:', error);
                        }
                      }}
                      className="text-orange-500 hover:text-orange-800 font-semibold"
                    >
                      Mark All as Read
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div 
              className="flex items-center cursor-pointer" 
              onClick={handleProfileClick}
            >
              {avatar ? (
                <img 
                  src={avatar} 
                  alt={`Teacher ${user?.name}`} 
                  className="w-9 h-9 rounded-full object-cover object-center shadow-md"
                />
              ) : (
                <div className="w-10 h-10 rounded-full bg-gray-300 flex items-center justify-center">
                  <span className="text-gray-600 text-xs">{user?.name?.charAt(0)}</span>
                </div>
              )}
              <span className="font-bold text-white ml-2">Teacher {user?.name}</span>
            </div>
            <div className="relative group">
              <LogOut 
                size={20} 
                onClick={handleLogout} 
                className="text-white cursor-pointer"
              />
              <span className="absolute top-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                {/* 登出 */}
              </span>
            </div>
          </div>
        </div>
      </header>

      {/* 可隐藏的导航栏 */}
      <nav 
        className={`fixed top-12 left-0 right-0 bg-white shadow z-20 transition-all duration-600 ${
          showNav ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        // onMouseEnter={() => setShowNav(true)}
        // onMouseLeave={() => setShowNav(false)}
      >
        <div className="container mx-auto px-8 py-2">
          <ul className="flex justify-between text-rg items-center">
            <li>
              <NavLink to="/" className={({isActive}) => isActive ? "nav-item text-green-500 text-b-2 border-b-2 border-green-500 flex items-center" : "nav-item flex items-center"}>
                <Home size={25} className="mr-1" /> Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/class" className={({isActive}) => isActive ? "nav-item text-green-500 border-b-2 border-green-500 flex items-center" : "nav-item flex items-center"}>
                <Users size={25} className="mr-1" /> Class
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/classrecorder" className={({isActive}) => isActive ? "nav-item text-green-500 border-b-2 border-green-500 flex items-center" : "nav-item flex items-center"}>
                <FileText size={25} className="mr-1" /> Class Records
              </NavLink>
            </li> */}

            {/* 修改下拉菜单，确保默认隐藏 */}
            {/* 重构下拉菜单 */}
            <li className="relative group">
              <div className="nav-item flex items-center cursor-pointer h-full">
                <FileText size={25} className="mr-1" /> Class Records
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1 transform group-hover:rotate-180 transition-transform duration-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </div>
              
              {/* 优化下拉菜单结构 */}
              <div className="absolute left-0 top-full mt-1 w-48 z-30 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 transform origin-top scale-95 group-hover:scale-100">
                <div className="bg-white shadow-lg rounded-md border border-gray-100 overflow-hidden">
                  <NavLink to="/classrecorder" className={({isActive}) => 
                    isActive 
                      ? "block px-4 py-2 text-sm text-green-500 bg-gray-50 hover:bg-gray-100 flex items-center" 
                      : "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                  }>
                    <FileText size={14} className="mr-2" />
                    Class History
                  </NavLink>
                  <NavLink to="/homework-management" className={({isActive}) => 
                    isActive 
                      ? "block px-4 py-2 text-sm text-green-500 bg-gray-50 hover:bg-gray-100 flex items-center" 
                      : "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                  }>
                    <BookOpen size={14} className="mr-2" />
                    Homework
                  </NavLink>
                </div>
              </div>
            </li>
            <li>
              <NavLink to="/webinar-and-training" className={({isActive}) => isActive ? "nav-item text-green-500 border-b-2 border-green-500 flex items-center" : "nav-item flex items-center"}>
                <BookOpen size={25} className="mr-1" /> Webinar and Training
              </NavLink>
            </li>
            <li>
              <NavLink to="/teacher-profile" className={({isActive}) => isActive ? "nav-item text-green-500 border-b-2 border-green-500 flex items-center" : "nav-item flex items-center"}>
                <User size={25} className="mr-1" /> Teacher Profile
              </NavLink>
            </li>
            <li>
              <NavLink to="/notifications" className={({isActive}) => isActive ? "nav-item text-green-500 border-b-2 border-green-500 flex items-center" : "nav-item flex items-center"}>
                <Bell size={25} className="mr-1" /> Notification
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>

      {/* Ignore Confirmation Modal */}
      {showIgnoreModal && currentIgnoreRequest && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg w-[480px]">
            <h2 className="text-lg font-semibold mb-4">Ignore Booking Request</h2>
            
            <div className="mb-6 bg-gray-50 p-4 rounded">
              <div className="mb-2">
                <span className="text-gray-600">Student:</span> 
                <span className="font-semibold ml-2">{currentIgnoreRequest.context.studentName}</span>
              </div>
              <div className="mb-2">
                <span className="text-gray-600">Course:</span> 
                <span className="font-semibold ml-2">{currentIgnoreRequest.context.courseName}</span>
              </div>
              <div className="mb-2">
                <span className="text-gray-600">Date:</span> 
                <span className="font-semibold ml-2">{currentIgnoreRequest.context.courseDate}</span>
              </div>
              <div className="mb-2">
                <span className="text-gray-600">Time:</span> 
                <span className="font-semibold ml-2">{currentIgnoreRequest.context.startTime} - {currentIgnoreRequest.context.endTime}</span>
              </div>
            </div>
            
            <div className="bg-orange-50 border-l-4 border-orange-500 p-4 mb-6">
              <div className="flex">
                <AlertCircle className="text-orange-500 mr-3" size={24} />
                <p className="text-sm">
                  <span className="font-bold">Warning:</span> This action will permanently close the request and you will not be able to process it again. The student will not be notified.
                </p>
              </div>
            </div>

            {/* Button Group */}
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => {
                  setShowIgnoreModal(false);
                  setCurrentIgnoreRequest(null);
                }}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={confirmIgnoreRequest}
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Confirm Ignore
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Main Content */}
      <div className="flex-grow pt-20 pb-16"> {/* Added pb-16 for footer space */}
        <main className="container mx-auto px-8 py-6">
          <Outlet />
        </main>
      </div>

      {/* 添加 ChatWidget 并传递用户信息 */}
      {user && (
        <ChatWidget 
          user={{
            id: user.id,
            name: user.name,
            role: 'englishteacher', // 从用户信息中获取或设置默认值
          }} 
        />
      )}

      {/* Footer */}
      <footer className="bg-gradient-to-r from-purple-300 to-blue-400 text-gray-600 py-2 mt-6">
        <div className="container mx-auto px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <h3 className="font-semibold text-base mb-2 flex items-center">
                <Phone size={16} className="mr-2" /> Contact Us
              </h3>
              <p className="text-sm flex items-center">
                <Phone size={14} className="mr-2" /> Tel: +86 21-51603680 / +1 508-645-6286
              </p>
              <p className="text-sm flex items-center">
                <Mail size={14} className="mr-2" /> Email: hr@theoryabc.com
              </p>
            </div>
            <div>
              <h3 className="font-semibold text-base mb-2 flex items-center">
                <MapPin size={16} className="mr-2" /> Address
              </h3>
              <p className="text-sm">1-30 Minbei Road, Building 104,</p>
              <p className="text-sm">A District, 1st Floor,</p>
              <p className="text-sm">Minhang District, Shanghai, China</p>
            </div>
            <div>
              <h3 className="font-semibold text-base mb-2 flex items-center">
                <ExternalLink size={16} className="mr-2" /> Quick Links
              </h3>
              <ul className="text-sm">
                <li><a href="#" className="hover:text-green-500 flex items-center"><ExternalLink size={14} className="mr-2" /> About Us</a></li>
                <li><a href="#" className="hover:text-green-500 flex items-center"><ExternalLink size={14} className="mr-2" /> FAQ</a></li>
                <li><a href="#" className="hover:text-green-500 flex items-center"><ExternalLink size={14} className="mr-2" /> Privacy Policy</a></li>
                <li><a href="#" className="hover:text-green-500 flex items-center"><ExternalLink size={14} className="mr-2" /> Terms of Service</a></li>
              </ul>
            </div>
          </div>
          <div className="border-t border-gray-200 mt-4 pt-4 text-xs text-center">
            <p>Theory ABC (Shanghai) Education Technology Co., Ltd. &copy; {new Date().getFullYear()} All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;