import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './contexts/PrivateRoute';
import Layout from './Layout';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { ClipLoader } from 'react-spinners';
import { Buffer } from 'buffer';
// import ChatWidget from './components/ChatWidget';


global.Buffer = Buffer;
// 懒加载组件
const Home = lazy(() => import('./Home'));
const Login = lazy(() => import('./Login'));
const ClassSchedule = lazy(() => import('./ClassSchedule'));
const HomeworkSystemComponents = lazy(() => import('./HomeworkSystemComponents'));
const ClassRecorder = lazy(() => import('./classrecorder'));
const WebinarAndTraining = lazy(() => import('./WebinarAndTraining'));
const TeacherProfile = lazy(() => import('./TeacherProfile'));
const Notifications = lazy(() => import('./Notifications'));
const ResetPassword = lazy(() => import('./ResetPassword')); // 添加这行
const TeacherDetail = lazy(() => import('./homework/TeacherDetail'));

function LoadingSpinner() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <ClipLoader color="#36D7B7" size={50} />
      <span style={{ marginLeft: '10px' }}>Loading...</span>
    </div>
  );
}

function App() {
  console.log('App component rendering...'); // 添加日志

  return (
    <AuthProvider>
      <Router>
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            
            <Route 
              path="/homework-review" 
              element={
                <PrivateRoute>
                  <HomeworkSystemComponents />
                </PrivateRoute>
              } 
            />

            <Route path="/" element={
              <PrivateRoute>
                <Layout />
              </PrivateRoute>
            }>
              <Route index element={<Home />} />
              <Route path="class" element={<ClassSchedule />} />
              <Route path="classrecorder" element={<ClassRecorder />} />
              <Route path="webinar-and-training" element={<WebinarAndTraining />} />
              <Route path="teacher-profile" element={<TeacherProfile />} />
              <Route path="notifications" element={<Notifications />} /> 
              <Route path="homework-management" element={<TeacherDetail />} />
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Suspense>
      </Router>
    </AuthProvider>
  );
}

export default App;