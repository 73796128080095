import React, { useState, useEffect, useRef } from 'react';
import { Smile } from 'lucide-react';

const EmojiPicker = ({ onEmojiSelect, buttonRef }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showChristmasEmojis, setShowChristmasEmojis] = useState(false);
  const pickerRef = useRef(null);
  
  // 常规表情
  const regularEmojis = [
    { emoji: '😊', name: 'smile' },
    { emoji: '😂', name: 'joy' },
    { emoji: '😍', name: 'heart_eyes' },
    { emoji: '👍', name: 'thumbs_up' },
    { emoji: '👎', name: 'thumbs_down' },
    { emoji: '❤️', name: 'heart' },
    { emoji: '😢', name: 'cry' },
    { emoji: '😡', name: 'angry' },
    { emoji: '🎉', name: 'party' },
    { emoji: '👋', name: 'wave' },
    { emoji: '🙏', name: 'pray' },
    { emoji: '🤔', name: 'thinking' },
    { emoji: '😴', name: 'sleeping' },
    { emoji: '🤗', name: 'hug' },
    { emoji: '😎', name: 'cool' },
  ];
  
  // 圣诞表情
  const christmasEmojis = [
    { emoji: '🎄', name: 'christmas_tree' },
    { emoji: '🎅', name: 'santa' },
    { emoji: '🎁', name: 'gift' },
    { emoji: '⛄', name: 'snowman' },
    { emoji: '❄️', name: 'snowflake' },
    { emoji: '🦌', name: 'deer' },
    { emoji: '🔔', name: 'bell' },
    { emoji: '🧦', name: 'sock' },
  ];
  
  // 检查是否在圣诞节期间（12月15日至次年1月3日）
  useEffect(() => {
    const checkChristmasSeason = () => {
      const now = new Date();
      const month = now.getMonth(); // 0-11
      const day = now.getDate();
      
      // 12月15日至12月31日，或1月1日至1月3日
      return (month === 11 && day >= 15) || (month === 0 && day <= 3);
    };
    
    setShowChristmasEmojis(checkChristmasSeason());
  }, []);
  
  // 点击外部关闭表情选择器
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        pickerRef.current && 
        !pickerRef.current.contains(event.target) && 
        buttonRef.current && 
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [buttonRef]);
  
  // 切换表情选择器显示状态
  const togglePicker = () => {
    setIsOpen(!isOpen);
  };
  
  // 选择表情
  const handleEmojiClick = (emoji) => {
    onEmojiSelect(emoji);
    setIsOpen(false);
  };
  
  return (
    <div className="relative">
      {/* 表情按钮 */}
      <button 
        ref={buttonRef}
        onClick={togglePicker}
        className="text-gray-400 hover:text-purple-500 focus:outline-none"
        title="Insert Emoji"
      >
        <Smile size={20} />
      </button>
      
      {/* 表情选择器面板 */}
      {isOpen && (
        <div 
          ref={pickerRef}
          className="absolute bottom-8 right-0 bg-white rounded-lg shadow-lg p-2 border border-gray-200 z-10 w-64"
        >
          <div className="grid grid-cols-5 gap-2">
            {regularEmojis.map((item) => (
              <button
                key={item.name}
                onClick={() => handleEmojiClick(item)}
                className="hover:bg-gray-100 rounded p-1 text-2xl"
                title={item.name}
              >
                {item.emoji}
              </button>
            ))}
            
            {showChristmasEmojis && (
              <>
                <div className="col-span-5 border-t border-gray-200 my-1 pt-1">
                  <span className="text-xs text-gray-500">Christmas Special Emojis</span>
                </div>
                {christmasEmojis.map((item) => (
                  <button
                    key={item.name}
                    onClick={() => handleEmojiClick(item)}
                    className="hover:bg-gray-100 rounded p-1 text-2xl"
                    title={item.name}
                  >
                    {item.emoji}
                  </button>
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EmojiPicker;