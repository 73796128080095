import React, { useState, useEffect, useRef } from 'react';
import { 
  X, 
  RotateCw, 
  RotateCcw, 
  ZoomIn, 
  ZoomOut, 
  RefreshCw,
  Download,
  Maximize,
  Loader
} from 'lucide-react';

const ImageViewer = ({ 
  isOpen, 
  onClose, 
  imageUrl, 
  imageName, 
  originalUrl = null,
  isOriginal = false,
  onLoadOriginal
}) => {
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);
  const [isFlippedX, setIsFlippedX] = useState(false);
  const [isFlippedY, setIsFlippedY] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  
  const imageContainerRef = useRef(null);
  
  // 重置图片状态
  useEffect(() => {
    if (isOpen) {
      setRotation(0);
      setScale(1);
      setIsFlippedX(false);
      setIsFlippedY(false);
      setImageLoaded(false);
      setPosition({ x: 0, y: 0 });
    }
  }, [isOpen, imageUrl]);
  
  // 添加鼠标滚轮事件监听
  useEffect(() => {
    const handleWheel = (e) => {
      if (!isOpen || !imageContainerRef.current) return;
      
      e.preventDefault();
      
      // 确定缩放方向
      const delta = e.deltaY < 0 ? 0.1 : -0.1;
      const newScale = Math.max(0.5, Math.min(3, scale + delta));
      
      setScale(newScale);
    };
    
    const container = imageContainerRef.current;
    if (container) {
      container.addEventListener('wheel', handleWheel, { passive: false });
    }
    
    return () => {
      if (container) {
        container.removeEventListener('wheel', handleWheel);
      }
    };
  }, [isOpen, scale]);
  
  // 添加鼠标移动和松开事件监听
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isDragging) return;
      
      // 计算新位置
      const newX = e.clientX - dragStart.x;
      const newY = e.clientY - dragStart.y;
      
      // 限制拖动范围，防止图片完全拖出视图
      const containerWidth = imageContainerRef.current?.clientWidth || 800;
      const containerHeight = imageContainerRef.current?.clientHeight || 600;
      
      // 根据缩放比例计算图片的实际尺寸
      const imgWidth = (imageContainerRef.current?.querySelector('img')?.naturalWidth || 100) * scale;
      const imgHeight = (imageContainerRef.current?.querySelector('img')?.naturalHeight || 100) * scale;
      
      // 计算最大可拖动范围
      const maxX = Math.max(0, (imgWidth - containerWidth) / 2);
      const maxY = Math.max(0, (imgHeight - containerHeight) / 2);
      
      // 限制在可拖动范围内
      const limitedX = Math.max(-maxX, Math.min(maxX, newX));
      const limitedY = Math.max(-maxY, Math.min(maxY, newY));
      
      setPosition({ x: limitedX, y: limitedY });
    };
    
    const handleMouseUp = () => {
      setIsDragging(false);
    };
    
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
    
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, dragStart, scale]);
  
  // 处理鼠标按下事件
  const handleMouseDown = (e) => {
    if (scale <= 1) return; // 只有放大时才能拖动
    
    setIsDragging(true);
    setDragStart({
      x: e.clientX - position.x,
      y: e.clientY - position.y
    });
  };
  
  // 如果模态框未打开，不渲染任何内容
  if (!isOpen) return null;
  
  // 旋转图片
  const rotateClockwise = () => {
    setRotation((prev) => (prev + 90) % 360);
  };
  
  const rotateCounterClockwise = () => {
    setRotation((prev) => (prev - 90 + 360) % 360);
  };
  
  // 缩放图片
  const zoomIn = () => {
    setScale((prev) => Math.min(prev + 0.25, 3));
  };
  
  const zoomOut = () => {
    setScale((prev) => Math.max(prev - 0.25, 0.5));
  };
  
  // 翻转图片
  const flipHorizontal = () => {
    setIsFlippedX((prev) => !prev);
  };
  
  const flipVertical = () => {
    setIsFlippedY((prev) => !prev);
  };
  
  // 下载图片
  const downloadImage = () => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = imageName || 'image';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  // 加载原图
  const handleLoadOriginal = async () => {
    if (isOriginal || !originalUrl || !onLoadOriginal) return;
    
    setIsLoading(true);
    setImageLoaded(false);
    try {
      await onLoadOriginal();
    } catch (error) {
      console.error('Load original image failed:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  // 图片加载完成
  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  
  // 图片样式
  const imageStyle = {
    transform: `
      translate(${position.x}px, ${position.y}px)
      rotate(${rotation}deg) 
      scale(${scale}) 
      scaleX(${isFlippedX ? -1 : 1}) 
      scaleY(${isFlippedY ? -1 : 1})
    `,
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    transition: isDragging ? 'none' : 'transform 0.2s ease-out',
  };
  
  // 拖动时的光标样式
  const dragCursorStyle = {
    cursor: scale > 1 ? (isDragging ? 'grabbing' : 'grab') : 'default'
  };
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      {/* 关闭按钮 */}
      <button 
        onClick={onClose}
        className="absolute top-4 right-4 text-white hover:text-gray-300 focus:outline-none"
      >
        <X size={24} />
      </button>
      
      <div className="bg-white rounded-lg shadow-xl overflow-hidden max-w-[95%] max-h-[95%]">
        {/* 图片标题 */}
        <div className="px-4 py-2 border-b border-gray-200 flex items-center justify-between">
          <h3 className="font-medium text-gray-700 truncate max-w-[500px]">
            {imageName || 'Image Preview'}
          </h3>
          <div className="text-xs text-gray-500">
            {isOriginal ? 'Original Image' : 'Preview Image'}
          </div>
        </div>
        
        <div>
          {/* 图片显示区域 - 更大的固定大小 */}
          <div 
            ref={imageContainerRef}
            className="bg-gray-100 flex items-center justify-center relative overflow-hidden" 
            style={{ width: '650px', height: '450px', ...dragCursorStyle }} // 更大的固定大小
            onMouseDown={handleMouseDown}
          >
            {/* 加载指示器 */}
            {(!imageLoaded || isLoading) && (
              <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-100 bg-opacity-80 z-10">
                <Loader size={32} className="text-purple-600 animate-spin mb-2" />
                <p className="text-gray-700 text-sm">
                  {isLoading ? 'Loading original image...' : 'Loading image...'}
                </p>
              </div>
            )}
            
            {/* 图片 */}
            <img 
              src={imageUrl} 
              alt={imageName}
              style={imageStyle}
              className={`transition-opacity duration-300 ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
              onLoad={handleImageLoad}
              onError={(e) => {
                console.error('Image loading failed:', imageUrl);
                e.target.src = '/images/image-placeholder.png';
                setImageLoaded(true);
              }}
              draggable="false"
            />
            
            {/* 缩放提示 */}
            {scale > 1 && (
              <div className="absolute bottom-2 left-2 bg-black bg-opacity-50 text-white text-xs px-2 py-1 rounded">
                {Math.round(scale * 100)}% - Drag to move the image
              </div>
            )}
          </div>
          
          {/* 工具栏 */}
          <div className="p-2 border-t border-gray-200 bg-white">
            <div className="flex items-center justify-between">
              <div className="flex space-x-1">
                {/* 旋转按钮 */}
                <button 
                  onClick={rotateCounterClockwise}
                  className="p-1.5 rounded-full hover:bg-gray-100"
                  title="Counterclockwise Rotation"
                >
                  <RotateCcw size={16} className="text-gray-500" />
                </button>
                <button 
                  onClick={rotateClockwise}
                  className="p-1.5 rounded-full hover:bg-gray-100"
                  title="Clockwise Rotation"
                >
                  <RotateCw size={16} className="text-gray-500" />
                </button>
                
                {/* 缩放按钮 */}
                <button 
                  onClick={zoomOut}
                  className="p-1.5 rounded-full hover:bg-gray-100"
                  title="Zoom Out"
                  disabled={scale <= 0.5}
                >
                  <ZoomOut size={16} className={scale <= 0.5 ? 'text-gray-300' : 'text-gray-500'} />
                </button>
                <button 
                  onClick={zoomIn}
                  className="p-1.5 rounded-full hover:bg-gray-100"
                  title="Zoom In"
                  disabled={scale >= 3}
                >
                  <ZoomIn size={16} className={scale >= 3 ? 'text-gray-300' : 'text-gray-500'} />
                </button>
                
                {/* 翻转按钮 */}
                <button 
                  onClick={flipHorizontal}
                  className="p-1.5 rounded-full hover:bg-gray-100"
                  title="Horizontal Flip"
                >
                  <RefreshCw size={16} className="text-gray-500 transform rotate-90" />
                </button>
                <button 
                  onClick={flipVertical}
                  className="p-1.5 rounded-full hover:bg-gray-100"
                  title="Vertical Flip"
                >
                  <RefreshCw size={16} className="text-gray-500" />
                </button>
              </div>
              
              <div className="flex space-x-1">
                {/* 下载按钮 */}
                <button 
                  onClick={downloadImage}
                  className="p-1.5 rounded-full hover:bg-gray-100"
                  title="Download Image"
                >
                  <Download size={16} className="text-gray-500" />
                </button>
                
                {/* 加载原图按钮 */}
                {!isOriginal && originalUrl && (
                  <button 
                    onClick={handleLoadOriginal}
                    className="flex items-center px-2 py-1 bg-purple-600 text-white rounded hover:bg-purple-700 disabled:bg-purple-300 text-xs"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Loading...' : 'View Original'}
                    <Maximize size={12} className="ml-1" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageViewer;