import imageCompression from 'browser-image-compression';

export async function compressImage(file) {
  const options = {
    maxSizeMB: 1, // 将图片压缩到 1MB 以下
    maxWidthOrHeight: 1920, // 可根据需求设置，保证图片不失真太多
    useWebWorker: true,
  };

  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    console.error('图片压缩失败:', error);
    throw error;
  }
}